.area {
    border-radius: 0.5rem; 
    padding: 1rem;
    margin: 1rem;
}

.area > h4 {
    font-weight: bold;
}

.setting {
    padding: 1rem;
    margin: 1rem 0 0 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;  
    display: flex;
    flex-direction: column;
}

.setting_inner {
    align-items: center; 
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.input, .btn_submit {
    margin: 1.25rem 0; 
}

.input {
    max-width: 30rem;
}

.input > input {
    line-height: 2rem !important;
}

.btn_submit_inner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}