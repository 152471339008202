.inner {
    display: flex;
    align-items: center;
}

.btn_wrap {
    display: flex;
    margin-left: auto;
}

.more_btn, .info_btn {
    margin-left: 0.5rem;
}
 
.item, .message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.message { 
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.item_link {
    font-size: 1rem;
} 

.action {
    display: flex;
    background-color: rgba(135, 100, 220, .1); 
    padding: 0.5rem;
    border-radius: 0.375rem; 
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.action_btn_wrap {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
}
 
.del_btn, .cancel_btn, .copy_btn, .answered_btn {
    display: flex;
    align-items: center; 
    gap: 0.5rem;
}
  
.modal_message_footer {
    justify-content: flex-start; 
}

.modal_message_btns_wrap {
    display: flex;
    margin-left: auto;
    gap: 0.5rem;
}