.navbar {
    background-color: rgb(135, 100, 220);
}

.container {
    max-width: 1400px;
}

.collapse {
    overflow: hidden;
}

.nav {
    overflow: hidden;
}

.nav:first-child {
    overflow: visible;
}

.logo_img {
    height: 2.5rem;
}
 
.link, .name {
    color: white !important;
    border-radius: 0.375rem;  
    margin: 0 0.25rem; 
    white-space: nowrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}

.link {
    flex-shrink: 0;
}

.name {
    overflow: hidden;
}

.name_inner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
 
.link:hover, .name:hover {
    background-color: rgb(110, 73, 196);
}

.link:active, .name:active {
    background-color: rgb(99, 65, 180);
}
 
.acc_img, .basket_img { 
    margin-right: 0.5rem;
} 

.acc_img { 
    flex-shrink: 0;
} 

.spinner_wrap {
    display: flex;
    align-items: center;
    margin: 0 0.25rem; 
    padding: 0 0.5rem;
}

.spinner {
    color: white !important;
    width: 1.5rem;
    height: 1.5rem;
}

@media (min-width: 768px) { 
    .container {
        align-items: stretch !important;
    }
    .nav {
        height: 100%;
        align-items: stretch !important;
    }
    .acc_img {
        flex-shrink: 0;
    }
}
