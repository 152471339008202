.App {
  box-sizing: border-box;
  font-family: Lunasima-Regular;
}

.router_inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content_wrap {
  flex: 1;
}

.content {
  margin: 0 auto;
  max-width: 1400px;
}