.form_wrap {
    flex-grow: 1;
    overflow: hidden;
    padding: 1rem;
}

.form {
    margin: 3rem auto;
    max-width: 30rem; 
    padding: 2rem;
    background-color: rgba(135, 100, 220, .1);
    border-radius: 0.5rem; 
} 

.title {
    padding: 1rem 0;
    
}

.select { 
    margin: 1.25rem 0; 
    line-height: 2rem !important;
}

.input {
    margin: 1.25rem 0;
}

.input > input {
    line-height: 2rem !important;
}

.download {
    margin-bottom: 0.5rem;
    width: max-content;
}

.btns_wrap {
    display: flex;
    justify-content: space-between;
}

.btn_submit, .reject_btn{
    margin: 1.25rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.info_fetch { 
    display: flex;
    justify-content: center;
    margin: 10rem 0; 
}

.discount_label {
    display: flex;
    align-items: center;
}

@media (max-width: 480px) {
    .form {
        margin: 1.25rem auto;
    } 
  }
 