.footer {
    background-color: rgba(135, 100, 220, .1);
    margin-top: 1rem;
}

.footer_inner {
    margin: 0 auto;
    padding: 1rem;
    max-width: 1400px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.links {
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.shop > h5, .help > h5 {
    margin: 1rem 0;
    font-weight: bold;
}

.help > a, .shop > a {
    cursor: pointer;
    margin-top: 0.5rem;
}

.help > a:hover, .shop > a:hover {
    text-decoration: underline;
}

.logo_img {
    height: 3rem;
}

.brand {
    margin-top: 2rem;
}

.not_involved {
    font-style: italic;
    margin-top: 1rem;
    text-align: center;
    max-width: 22rem;
}