.toast {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    width: auto;
    opacity: 0.9;
}

.close_btn {
    margin-right: 0.75rem;
}