.btn-prime {
    background-color: rgb(135, 100, 220) !important;
    border: 1px solid rgb(135, 100, 220) !important;
}

.btn-prime:hover {
    background-color: rgb(110, 73, 196) !important;
    border: 1px solid rgb(105, 67, 192) !important;
}

.btn-prime:active {
    background-color: rgb(99, 65, 180) !important;
    border: 1px solid rgb(90, 55, 170) !important;
}

.btn-second {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.175) !important;
    color: black !important;
} 

.btn-second:hover {
    background-color: rgb(240, 240, 240) !important;
}

.btn-second:active {
    background-color: rgb(230, 230, 230) !important;
}

.link {
    color: rgb(13, 110, 253) !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.link:hover {
    color: rgb(10, 88, 202) !important;
}
