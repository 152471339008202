.profile {
    background-color: rgba(135, 100, 220, .1);
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
}

.info {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 1rem;
}
 
.data {
    align-items: start;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow: hidden;
}

.details,
.statistics {
    padding: 1rem;
    background-color: white; 
    border-radius: 0.375rem;
    width: 100%;
    overflow: hidden;
}

.details > h5,
.statistics > h5 {
    font-weight: bold;
}

.details > div > span, .statistics > div > span {
    color: rgba(0, 0, 0, 0.5);
}

.details > div, .statistics > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.avatar, .avatar_placeholder {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
}

.btns_wrap {
    gap: 0.5rem;
    display: flex;
}

.edit_btn, .logout_btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.likes {
    margin: 3rem 0 1rem 0;
}

.history {
    margin-top: 1rem;
}

.history>h5,
.likes>h5 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: bold;
}

.heart_img,
.history_img {
    margin-left: 0.5rem;
}

.no_likes_wrap,
.no_history_wrap {
    display: flex; 
    justify-content: center;
    margin: 10rem 0;
    text-align: center;
}

.no_likes_wrap > h4,
.no_history_wrap > h4 {
   font-weight: bold;
}

.avatar_plaseholder {
    border-radius: 0.375rem;
    border: 1px solid #dee2e6;
}

@media (min-width: 576px) {
    .details,
    .statistics,
    .actions {
        width: auto;
    }
}