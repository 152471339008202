.card {
    margin: 1rem;
}

.name { 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.125rem;
    font-weight: bold;
}  

.author { 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
}

.img {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.img_placeholder { 
    border-radius: calc(.375rem - 1px); 
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.text { 
    margin-bottom: 1rem;
}

.btns {
    display: flex;
    align-items: center; 
}

.price { 
    margin: 0 1rem;
    display: flex;
    align-items: center;
}

.price_placeholder {
    width: 3rem;
}

.close_btn_sm {
    margin: 0.5rem; 
    display: none;
}

.close_btn_xsm {
    margin-left: auto;
}

.discount {
    background-color: #dc3545;
    border-radius: calc(.375rem - 1px); 
    color: white;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    position: absolute;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; 
}

@media (min-width: 576px) {
    .card {
        flex-direction: row;
    }
    .ratio {
        width: 16rem;
        flex-shrink: 0;  
    }
    .img {
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: calc(.375rem - 1px) !important;
    }
    .img_placeholder {
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: calc(.375rem - 1px) !important;
    }
    .info {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .text {
        margin-bottom: 0;
    }
    .close_btn_sm { 
        display: inline-block; 
    }
    .close_btn_xsm { 
        display: none;
    }
}