.basket {
    display: flex;
    flex-direction: column;
}

.control {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
}

.clear_btn {
    margin-left: 0.5rem;
}
 
.counter {
    margin: 1rem;
    display: flex;
    align-items: center;
}

.counter_badge {
    margin-left: 0.5rem;
}

.checkout_wrap {
    height: 20rem;
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    border-radius: 0.5rem; 
    background-color: rgba(135, 100, 220, .1);
}    

.checkout {
    width: 75%;
}

.checkout_btn {
    margin-bottom: 0.5rem;
    display: inline-flex;
    align-items: center;
} 

.checkout i {
    font-size: 0.875rem;
} 

.checkout > h4 {
    margin-bottom: 1rem;
    font-weight: bold;
}

.total_price {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.875rem;
}

.total_price_placeholder {
    width: 4rem;
}

.empty_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
    text-align: center;
}

.empty_wrap > h4 {
    font-weight: bold;
}

.spinner {
    margin-left: 0.5rem; 
}

@media (min-width: 992px) {
    .basket {
        flex-direction: row;
    }
    .products {
        width: 70%; 
    } 
    .checkout_wrap {
        width: 30%;
        margin: 1rem;
    }
}
