.card {
    margin: 1rem;
}

.name { 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.125rem;
    font-weight: bold;
} 

.author { 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap;
    font-size: 0.875rem;
}

.img {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.img_placeholder { 
    border-radius: calc(.375rem - 1px); 
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.text {
    margin-bottom: 1rem;
}

.btns {
    display: flex;
    align-items: center;
} 

.spinner {
    margin-left: 0.5rem;
}

.more_btn_sm, .more_btn_xsm {
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
}

.more_btn_sm {
    margin: 0.5rem; 
    display: none;
}

.more_btn_xsm {
    margin-left: auto;
}

.item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item_link {
    font-size: 1rem;
}

.action {
    display: flex;
    background-color: rgba(135, 100, 220, .1); 
    padding: 0.5rem;
    border-radius: 0.375rem; 
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.action_btn_wrap {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
}

.del_btn, .cancel_btn {
    display: flex;
    align-items: center; 
    gap: 0.5rem;
}

.cards {
    margin-bottom: 1rem;
}

.pagination {
    display: flex; 
    justify-content: center;
}

.pagination>ul {
    margin: 1rem !important;
}

.pag_item>a {
    background-color: white !important;
    color: black !important;
}

.pag_item_active>a {
    background-color: rgb(135, 100, 220) !important;
    color: white !important;
}

@media (min-width: 576px) {
    .card {
        flex-direction: row;
    }
    .ratio {
        width: 16rem;
        flex-shrink: 0; 
        display: flex; 
        align-items: stretch;
    }
    .img {
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: calc(.375rem - 1px) !important;
    }
    .img_placeholder {
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: calc(.375rem - 1px) !important;
    }
    .info {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .text {
        margin-bottom: 0;
    }
    .more_btn_sm { 
        display: inline-block; 
    }
    .more_btn_xsm { 
        display: none;
    }
}