.form {
    margin: 3rem auto;
    max-width: 30rem; 
    padding: 2rem;
    background-color: rgba(135, 100, 220, .1);
    border-radius: 0.5rem; 
} 

.title {
    padding: 1rem 0;
    font-weight: bold;
}

.input { 
    margin: 1.25rem 0; 
}  

.btn_submit {
    display: flex; 
    align-items: center;
    gap: 0.5rem;
}

.btns_wrap {
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0 1.25rem 0;
    flex-wrap: wrap;
    gap: 1rem;
}

.google_img {
    height: 1.25rem; 
    margin: 0 0.375rem 0.125rem 0; 
}

.links_wrap {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.link {
    display: inline-block;
}

@media (max-width: 480px) {
    .form { 
        margin: 1.25rem auto;
    }
  }
