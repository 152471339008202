.admin {
    display: flex;
    flex-direction: column;
}

.outlet {
    flex-grow: 1;
}

.nav {
    margin: 1rem;
} 

.item > a {   
    margin: 0.25rem;
    color: black !important;
    width: fit-content;
}

.item > a[class="nav-link active"]{   
    background-color: rgb(135, 100, 220) !important;
    color: white !important;
}  

.spinner_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
}

@media (min-width: 992px) {
    .admin {
        display: flex;
        flex-direction: row;
    }
    .nav {
        flex-direction: column;
    }
  }