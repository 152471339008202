.main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.search_section {
    display: flex;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    flex-direction: column;
    min-height: calc(-66px + 100vh)
}

.search_overview{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(135, 100, 220, .1);
    box-shadow: 1rem 1rem rgba(135, 100, 220, .2);
    border-radius: 0.5rem;
    width: 100%;
    max-width: 62rem;
    padding: 1rem;
    height: 24rem;
}

.search_overview_inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.search_header > h2 {
    margin-top: 1.5rem;
    font-weight: bold;
}

.search_btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.search_input {
    width: 100%;
}

.trust_section {
    margin: 1rem;
}

.trust_section > h3 {
    font-weight: bold;
    margin-bottom: 3rem;
}

.cards {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    align-items: start;
    flex-direction: column;
}

.card {
    width: 100%;
}

.card_title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.categories_section {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categories_btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@media (min-width: 768px) { 
    .search_section {
        flex-direction: row;
    }
    .search_header > h2 {
        margin: 0;
    }
    .search_input { 
        max-width: 30rem;
    }    
    .cards {
        flex-direction: row;
    }
    .card {
        width: 22rem;
    }
} 