.faq {
    margin: 1rem;
}

.faq > h4 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0;
    font-weight: bold;
    flex-wrap: wrap;
}