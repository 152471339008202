.form {
    margin: 3rem auto;
    max-width: 30rem; 
    padding: 2rem;
    background-color: rgba(135, 100, 220, .1);
    border-radius: 0.5rem; 
} 

.title {
    padding: 1rem 0;
    font-weight: bold;
}

.input > input, .select {
    line-height: 2rem !important;
}

.btn_submit, .input {
    margin: 1.25rem 0;
}

.btn_submit_inner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

@media (max-width: 480px) {
    .form {
        margin: 1.25rem auto;
    } 
  }
