.statistics {
    flex-grow: 1;
    margin: 1rem;
}

.counts {
    display: flex;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgba(0, 0, 0, 0.175);
    padding: 1rem;
    border-radius: 0.375rem; 
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
} 

.count {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.ratio {
    margin: 3rem 0;
}

.spinner_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
}
