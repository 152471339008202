.item {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.created {
    flex-shrink: 0;
} 

.name_placeholder {
    flex: 6;
}

.created_placeholder {
    flex: 1;
    text-align: end;
}
