.control {
    margin-top: 1rem;
    display: flex;
}

.select {
    max-width: 20rem;
}

.add_btn {
    margin-left: 0.5rem;
}

.table_wrap {
    overflow-x: auto;
    margin-top: 1rem;
}

.pagination { 
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.pag_item>a {
    background-color: white !important;
    color: black !important;
}

.pag_item_active>a {
    background-color: rgb(135, 100, 220) !important;
    color: white !important;
}

.not_found {
    display: flex;
    justify-content: center;
    margin: 10rem 0; 
    text-align: center;
  }

.not_found > h4 {
    font-weight: bold;
}

.spinner_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
}