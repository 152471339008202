.form_wrap {
    margin: 3rem 0;
}

.form, .title  {
    margin: 1rem;
}

.title {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    flex-wrap: wrap;
}

.input {
    margin: 1.25rem 0;
    max-width: 35rem;
}

.input > input { 
    line-height: 2rem !important;
}

.btn_submit{ 
    margin: 1.25rem 0;
}

.btn_submit_inner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}