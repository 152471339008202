.alert {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}