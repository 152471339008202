.form {
    margin: 3rem auto;
    max-width: 30rem; 
    padding: 2rem;
    background-color: rgba(135, 100, 220, .1);
    border-radius: 0.375rem; 
} 

.title {
    padding: 1rem 0;
    font-weight: bold;
}

.select, .input > input {
    line-height: 2rem !important; 
} 

.btn_submit, .code_wrap {
    margin: 1.25rem 0;
}

.btn_submit_inner {
    display: flex; 
    align-items: center;
    gap: 0.5rem;
}

.code {
    width: 100%;
    max-width: 20rem;
    height: 3rem;
}

.code_character {
    background-color: rgb(255, 255, 255);
    border-radius: 0.375rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
    font-size: 1.25rem;
}

.code_character_err {
    background-color: rgb(255, 255, 255);
    border-radius: 0.375rem;
    border: 1px solid #dc3545;
    font-size: 1.25rem;
}

.code_character_selected {
    outline: none;
    color: #0b5ed7;
    border: 1px solid #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, .5);
}

.code_character_selected_err {
    outline: none;
    color: #bb2d3b;
    border: 1px solid #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, .5);
}

.err {
    font-size: .875em;
    margin-top: .25rem;
    width: 100%;
    color: #dc3545;
}

@media (max-width: 480px) {
    .form { 
        margin: 1.25rem auto;
    }
}
