.alert_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
}

.alert {
    max-width: 50rem;
    padding: 5rem;
    margin: 1rem;
}

.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
}

.additional {
    margin-bottom: 0;
}