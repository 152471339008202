.info {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    border-radius: 0.5rem; 
    background-color: rgba(135, 100, 220, .1);
    padding: 1rem;
    gap: 1rem;
}

.head {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
}

.head > div:first-child {
    flex: 1;
}  

.name {
    font-weight: bold;
}

.btns_wrap, .updated, .list_item > div {
    display: flex; 
    gap: 0.5rem;
    align-items: center;
}

.updated {
    height: 1.5rem;
}

.basket_btn, .share_btn {
    display: flex;
    align-items: center;
}

.basket_img, .share_img, .basket_spinner {
    margin-left: 0.5rem;
}

.btns_wrap {
    flex-wrap: wrap;
    flex-shrink: 0;
}

.heart_img {
    color: #dc3545;
} 

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.img {
    width: 100%;
    border-radius: 0.5rem; 
}

.ratio_plaseholder {
    border-radius: 0.5rem; 
}

.data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.foremost {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
    border-radius: 0.375rem; 
    padding: 1rem;
}

.price, .price_new, .price_discount_wrap {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 1.5rem;
}

.price_new {
    color: #dc3545; 
}

.price_discount_wrap {
    gap: 0.5rem;
}

.price, .price_discount_wrap {
    margin: 0;
}

.price_crossed {
    text-decoration: line-through;
    opacity: 0.5;
}

.description, .alert {
    margin: 0;
}

.list_item {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.list_item > span {
    flex: 1;
}

.list_item > span:last-child {
    text-align: end;
}

.not_found_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
    text-align: center;
}
  
.not_found_wrap > h4 {
font-weight: bold;
}

@media (min-width: 768px) {
    .head, .content {
        flex-direction: row;
    }
    .visual {
        width: 60%;
    }
    .data {
        width: 40%;
    }
}