.about_us {
    margin: 1rem;
}

.header {
    text-align: center;
}

.about_us > h3, .about_us > h4 {
    font-weight: bold;
    padding: 1rem 0;
}

.contacts_inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.telegram, .envelope {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.telegram_img, .envelope_img {
    color: black;
}