.not_found_wrap {
    display: flex;
    justify-content: center;
    margin: 10rem 0;
    align-items: center;
    gap: 0.5rem;
}

.not_found_wrap > h4 {
    font-weight: bold;
}
