.setting {
  margin: 1.5rem 1rem 0.5rem 1rem;
}

.setting_inner {
  display: flex; 
  align-items: center;
}

.drop_toggle_categories {
  background-color: rgb(135, 100, 220) !important;
  border: 1px solid rgb(135, 100, 220) !important;
}

.drop_toggle_categories:hover {
  background-color: rgb(110, 73, 196) !important;
  border: 1px solid rgb(105, 67, 192) !important;
}

.drop_toggle_categories:active {
  background-color: rgb(99, 65, 180) !important;
  border: 1px solid rgb(90, 55, 170) !important;
}

.drop_menu {
  min-width: 0;
}

.drop_toggle_in_categories, .drop_toggle_filter {
  background-color: white !important;
  color: black !important;
  border: none; 
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop_toggle_in_categories {
  border-radius: 0;
}

.drop_toggle_in_categories:hover, .drop_toggle_filter:hover {
  background-color: rgb(240, 240, 240) !important; 
}

.drop_toggle_in_categories:active, .drop_toggle_filter:active {
  background-color: rgb(230, 230, 230) !important;
}

.drop_item_placeholder_wrap {
  display: block;
  width: 8rem;
}

.drop_item:not(:first-child), .drop_item_placeholder_wrap:not(:first-child) {
  margin-top: 0.5rem;   
}

.drop_item:hover {
  background-color: rgb(240, 240, 240) !important; 
}
 
.drop_item:active {
  background-color: rgb(99, 65, 180) !important;
}

.drop_item_placeholder {
  background-color: currentcolor !important;
  color: currentcolor !important; 
  padding: 1rem;
}

.search_input_sm {
  max-width: 25rem;
  margin-left: 0.5rem;
  display: none;
}

.search_input_xsm {
  display: flex;
  margin-bottom: 1rem; 
}

.search_btn {
  display: flex;
  align-items: center;
}
 
.search_img {
  margin-left: 0.5rem;
}

.drop_filter {
  margin-left: auto;
}

.funne_img {
  margin-left: 0.5rem;
  color: rgb(135, 100, 220);
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.categorie { 
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: rgb(250, 250, 250);
  margin: 0.5rem 0 0.5rem 1rem;
  border-radius: 0.375rem; 
}

.categorie > span { 
  margin-right: 0.5rem;
}

.not_found_wrap {
  display: flex;
  justify-content: center;
  margin: 10rem 0;
  text-align: center;
}

.not_found_wrap > h4 {
  font-weight: bold;
}

@media (min-width: 576px) { 
  .search_input_sm {
    display: flex;
    margin-right: 0.5rem;
  }
   
  .search_input_xsm { 
    display: none;
  }
}
  