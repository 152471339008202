.info {
    margin: 1rem;
    flex-wrap: wrap;
}

.info, .count, .upload_btn, .balance, .edit_btn, .payouts_header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.count {
    margin-right: auto;
}

.balance {
    padding: 0.375rem 0.75rem;
    background-color: rgba(135, 100, 220, .1);
    border: 1px solid rgba(135, 100, 220, .1);
    border-radius: 0.375rem; 
}

.balance_placeholder { 
    width: 2.5rem;
}

.alert {
    margin: 1rem;
}

.line {
    margin: 0.5rem;
}

.line > span {
    color: rgba(0, 0, 0, 0.5);
}

.payouts {
    display: inline-block;
    width: 100%;
    border-radius: 0.375rem; 
    background-color: rgba(135, 100, 220, .1);
}

.payouts > hr {
    margin: 0;
}

.payouts_header {
    font-size: 1.25rem;
    margin: 0.5rem;
}

.author_fetch {
    display: flex;
    justify-content: center;
    margin: 10rem 0; 
}

.not_found {
    display: flex;
    justify-content: center;
    margin: 10rem 0; 
    text-align: center;
} 

.not_found > h4 {
    font-weight: bold;
}