.inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.more_btn {
    margin-left: 0.5rem;
}

.item { 
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item_link {
    font-size: 1rem;
}

.action {
    display: flex;
    background-color: rgba(135, 100, 220, .1); 
    padding: 0.5rem;
    border-radius: 0.375rem; 
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.action_btn_wrap {
    margin-left: auto;
    display: flex;
    gap: 0.5rem;
}
 
.del_btn, .cancel_btn {
    display: flex;
    align-items: center; 
    gap: 0.5rem;
}