.info {
    margin: 1rem;
}
 
.count {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}  

.not_found {
    display: flex;
    justify-content: center;
    margin: 10rem 0; 
    text-align: center;
}

.not_found > h4 {
    font-weight: bold;
}