.card {
    width: 100%;
} 

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-size: 1.125rem;
    font-weight: bold;
} 

.author { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-size: 0.875rem;
}

.info_wrap {
    display: flex;
    align-items: center;
} 

.basket_btn {
    margin-left: 0.5rem;
}

.basket_img {
    color: rgb(135, 100, 220);
}

.price {
    margin-left: auto; 
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
}

.price_placeholder {
    width: 3rem
}

.img_placeholder {
    border-top-left-radius: calc(.375rem - 1px);
    border-top-right-radius: calc(.375rem - 1px);
}

.basket_btn_placeholder {
    width: 3rem;
}

.discount {
    background-color: #dc3545;
    border-radius: calc(.375rem - 1px); 
    color: white;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    top: 0;
    right: 0;
}

.cards {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    margin: 1rem;
    grid-row-gap: 1rem;
}

.pagination {
    display: flex;
    justify-content: center;
}

.pagination>ul {
    margin: 1rem !important;
}

.pag_item>a {
    background-color: white !important;
    color: black !important;
}

.pag_item_active>a {
    background-color: rgb(135, 100, 220) !important;
    color: white !important;
}

@media (min-width: 576px) {  
    .cards {
        grid-template-columns: 1fr 1fr;
    }
  }
    
  @media (min-width: 992px) {
    .cards {
        grid-template-columns: 1fr 1fr 1fr;
    }
  }
    
  @media (min-width: 1400px) {
    .cards {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }